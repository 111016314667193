import React from "react"
import Logo from "../images/svg/logo-new.svg"
import classNames from "classnames"
import ReactTooltip from "react-tooltip"

const NavBar: React.FC = () => {
  return (
    <div className="fixed flex flex-wrap top-0 left-0 z-20 w-screen">
      <div className={classNames(`rounded-full transition-all flex uppercase bg-black filter backdrop-blur z-20 text-md font-sans p-0 my-4 ml-4 h-[50px] w-[50px] lg:h-[88px] lg:w-[88px]`)}>
        <a data-tip data-for="metadibs" href="https://metadibs.io/" target="_blank" rel="noreferrer">
          <Logo />
        </a>
        <ReactTooltip id="metadibs" type="dark">
            <div className="text-[12px] lg:text-[14px]">
                Drop by Metadibs
            </div>
        </ReactTooltip>
      </div>
    </div>
  )
}

export default NavBar
