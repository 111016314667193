import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Img from "gatsby-image"

const AllNFTs: React.FC = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
      partialVisibilityGutter: 45
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 45
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 45
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 45
    }
  };

  const { firstDrop, secondDrop, thirdDrop } = useStaticQuery(
    graphql`
      query {
        firstDrop: allFile(
          limit: 33
          filter: {relativeDirectory: {eq: "first-drop"}}
          sort: {order: ASC, fields: name}
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        secondDrop: allFile(
          limit: 99
          filter: {relativeDirectory: {eq: "second-drop"}}
          sort: {order: ASC, fields: name}
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        thirdDrop: allFile(
          limit: 100
          filter: {relativeDirectory: {eq: "third-drop"}}
          sort: {order: ASC, fields: name}
        ) {
          nodes {
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      {thirdDrop && thirdDrop?.nodes?.length > 0 && (
        <div className="bg-white">
          <div className="py-12 px-4 sm:px-6 lg:px-8 lg:py-18">
            <div className="space-y-12">
              <div className="max-w-[1200px] mx-auto space-y-5 sm:space-y-4">
                <h2 className="text-2xl font-extrabold text-gray-900">
                  TRANSITERATE, S03
                </h2>
                <p>
                  The third drop consists of 100 CNFTs. All are animated and loop.<br />
                  Policy: a76798b2d1b55c2030a756fb39f9838ea3d1adb569d6ae0d761fd99e
                </p>
              </div>
              <div
                  role="list"
                  className="space-y-12 sm:space-y-0"
                >
                  <Carousel
                    responsive={responsive}
                    partialVisible
                    swipeable
                    draggable
                    autoPlay
                  >
                    {thirdDrop.nodes.map((item) => (
                      <div className="mr-2" key={item.name}>
                        <Img
                          className="object-cover"
                          fluid={item.childImageSharp.fluid}
                        />
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Carousel>
                </div>
            </div>
          </div>
        </div>
        )}
      {secondDrop && secondDrop?.nodes?.length > 0 && (
      <div className="bg-white">
        <div className="py-12 px-4 sm:px-6 lg:px-8 lg:py-18">
          <div className="space-y-12">
            <div className="max-w-[1200px] mx-auto space-y-5 sm:space-y-4">
              <h2 className="text-2xl font-extrabold text-gray-900">
                DATA-MOMENTS, S02
              </h2>
              <p>
                The second drop consists of 99 CNFTs. All are animated and loop. All have sound.<br />
                Policy: de6278238440c2d106fbc0bf720de9cf291aaa96720c55d0e939250c
              </p>
            </div>
             <div
                role="list"
                className="space-y-12 sm:space-y-0"
              >
                <Carousel
                  responsive={responsive}
                  partialVisible
                  swipeable
                  draggable
                  autoPlay
                >
                  {secondDrop.nodes.map((item) => (
                    <div className="mr-2" key={item.name}>
                      <Img
                        className="object-cover"
                        fluid={item.childImageSharp.fluid}
                      />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </Carousel>
              </div>
          </div>
        </div>
      </div>
      )}

      {firstDrop && firstDrop?.nodes?.length > 0 && (
        <>
          <div className="bg-white">
            <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-18">
              <div className="space-y-12">
                <div className="max-w-[1200px] mx-auto space-y-5 sm:space-y-4">
                  <h2 className="text-2xl font-extrabold text-gray-900">
                    DATA-DONUTS, OG S01
                  </h2>
                  <p>
                    The first drop consists of 33 CNFTs. All are animated and loop.<br />
                    Policy: af1e74090d58363c6875fbf955c5808455b12986ac6efe4dbe0d852a
                  </p>
                </div>
                <div
                  role="list"
                  className="space-y-12 sm:space-y-0"
                >
                  <Carousel
                    responsive={responsive}
                    partialVisible
                    swipeable
                    draggable
                    autoPlay
                  >
                    {firstDrop.nodes.map((item) => (
                      <div className="mr-2" key={item.name}>
                        <Img
                          className="object-cover"
                          fluid={item.childImageSharp.fluid}
                        />
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default AllNFTs