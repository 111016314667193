import React from "react"

const JoinMailingList: React.FC = () => {
  return (
    <div className="flex flex-col mb-8">
      <div className="text-center my-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        Join our Metadibs mailing list<br /> for upcoming drops:
      </div>
      <a className="mx-auto" href="http://eepurl.com/hOEvxz" target="_blank" rel="noreferrer">
        <span className="rounded-full cursor-pointer inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4">
          Join Mailing List
        </span>
      </a>
    </div>
  )
}

export default JoinMailingList
