import React, {useState} from "react"
import Countdown from 'react-countdown'
import classNames from "classnames"
import Video from "../components/video"
import VideoFile from "../videos/hero/hero-video-s4.mp4"
import VideoPoster from "../videos/hero/hero-video-s4.jpg"
import PaymentGatewayButton from "./payment-gateway-button"

const Hero: React.FC = () => {
  const options = {
    soldOut: true,
    projectTitle: `IMAGINAL DISCS`,
    preSaleStartDate: new Date(Date.UTC(2022, 11, 15, 16, 0, 0)),
    preSaleEndDate: new Date(Date.UTC(2022, 11, 15, 19, 0, 0)),
    publicSaleStartDate: new Date(Date.UTC(2022, 11, 15, 19, 0, 1)),
  }

  const [preSaleActive, setPreSaleActive] = useState(false)
  const [preSaleEnded, setPreSaleEnded] = useState(false)

  // Renderer callback with condition
  const preSaleStartCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      const {projectTitle} = options

      setPreSaleActive(true);
      return (
        <>
          {preSaleActive && !preSaleEnded && (
            <div className="inline-block">
              <PaymentGatewayButton projectTitle={projectTitle} saleType="PRESALE" />

              {/** waiting for pre sale to stop */}
              <Countdown
                key="preSaleEndCountdown"
                date={options.preSaleEndDate}
                renderer={preSaleEndCountdown}
              />
            </div>
          )}

          {/** countdown for public sale starts */}
          {preSaleEnded && (
            <Countdown
              key="publicSaleStartCountdown"
              date={options.publicSaleStartDate}
              renderer={publicSaleStartCountdown}
            />
          )}
        </>
      );
    } else {
      return (
        <div className="flex flex-wrap justify-center">
          <span className={classNames(`rounded-full inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
            {`Pre Sale in ${days}d:${hours}h:${minutes}m:${seconds}s`}
          </span>
          <a href="https://www.jpg.store/collection/transiteratebyholgerlippmann" target="_blank" rel="noreferrer" className="ml-3">
            <span className={classNames(`rounded-full cursor-pointer inline-block bg-gray-200 filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
              TRANSITERATE
            </span>
          </a>
          <a href="https://www.jpg.store/collection/data-donuts" target="_blank" rel="noreferrer" className="ml-3">
            <span className={classNames(`rounded-full cursor-pointer inline-block bg-gray-200 filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
              DATA-DONUTS
            </span>
          </a>
          <a href="https://www.jpg.store/collection/data-moments" target="_blank" rel="noreferrer" className="ml-3">
            <span className={classNames(`rounded-full cursor-pointer inline-block bg-gray-200 filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
              DATA-MOMENTS
            </span>
          </a>
        </div>
      );
    }
  }
  const preSaleEndCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setPreSaleEnded(true);
      return ``;
    } else {
      return ``;
    }
  }
  const publicSaleStartCountdown = ({ days, hours, minutes, seconds, completed }) => {
    const {soldOut, projectTitle} = options

    if(soldOut){
      return (
        <div>
          <div className={classNames(`mt-3 max-w-md mx-auto text-base text-gray-500 md:mt-5 md:max-w-3xl`)}>
            Join our mailing list for upcoming drops or buy on JPG Store:<br /><br />
          </div>
          <a href="http://eepurl.com/hOEvxz" target="_blank" rel="noreferrer" className="mr-3">
            <span className={classNames(`rounded-full cursor-pointer inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
              Join Mailing List
            </span>
          </a>
          <a href="https://www.jpg.store/" target="_blank" rel="noreferrer" className="mr-3">
            <span className={classNames(`rounded-full cursor-pointer inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
              Buy on JPG Store
            </span>
          </a>
        </div>
      );
    } else if (completed) {
      return (
        <div className="inline-block">
          <PaymentGatewayButton projectTitle={projectTitle} saleType="PUBLICSALE" />
        </div>
      );
    } else {
      return (
        <>
        <span className={classNames(`rounded-full inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
          {`Public Sale in ${days}d:${hours}h:${minutes}m:${seconds}s`}
        </span>
        <a href="https://www.jpg.store/collection/data-donuts" target="_blank" rel="noreferrer" className="ml-3">
          <span className={classNames(`rounded-full cursor-pointer inline-block bg-gray-200 filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
            DATA-DONUTS
          </span>
        </a>
        <a href="https://www.jpg.store/collection/data-moments" target="_blank" rel="noreferrer" className="ml-3">
          <span className={classNames(`rounded-full cursor-pointer inline-block bg-gray-200 filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4`)}>
            DATA-MOMENTS
          </span>
        </a>
        </>
      );
    }
  }
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative mt-24 pt-6 pb-16 sm:pb-24">
          <div className="mx-auto max-w-[1200px] px-4 sm:px-6">
            <div className="text-center">
              <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                IMAGINAL-DISC<br />
                by HOLGER LIPPMANN
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-md md:mt-5 md:max-w-3xl mb-3">
                The fourth edition of artworks created by the pioneer of computer art, Holger Lippmann, is called IMAGINAL-DISC. The work is an exploration of the beauty of ordered and/or disordered data structures. It is generated from a radially arranged data pattern of different image-based, color-valued and color-localized data streams.
              </p>

              <div className="flex flew-wrap justify-center">
                <Countdown
                  key="preSaleStartCountdown"
                  date={options.preSaleStartDate}
                  renderer={preSaleStartCountdown}
                  />
              </div>
              <div className="absolute bottom-[5%] left-[50%] translate-x-[-50%]">
                <div className="animate-bounce">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6">
            <Video sources={[{src: VideoFile, type: "video/mp4"}]} poster={VideoPoster} className="w-full" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
