import React from "react"

type Props = {
  className?: string
  sources: Array<{
    src: string
    type: string
  }>
  poster: string
}

const Video: React.FC<Props> = ({ className = "", sources, poster }) => {
  return (
    <video
      muted
      autoPlay
      loop
      playsInline
      className={className}
      poster={poster}
    >
      {sources.map(({src, type}) => <source src={src} type={type} />)}
    </video>
  )
}

export default Video
