import React from "react"
import {GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import Video from "../components/video"
import VideoFile from "../videos/roadmap-2/roadmap-2.mp4"
import VideoPoster from "../videos/roadmap-2/roadmap-2.jpg"

const milestones = [
  {
    id: 1,
    name: '01 DATA-DONUT OG 33 drop 2021, Dec/23',
    description:
      'This is the start of DATA-DONUT drops. First owners of DATA-DONUTs are granted presale access to further DATA-DONUT drops in January, and to ongoing physical surprise. Each DATA-DONUT gives access to two additional NFT mints. All NFTs are sold out.',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: '02 DATA-MOMENTS 99 drop 2022, Feb/24',
    description:
      'Holders of the OG drop will get an exclusive chance to mint before the public sale. All NFTs are sold out.',
    icon: ScaleIcon,
  },
  {
    id: 3,
    name: '03 IMAGINAL-DISCS 111 drop 2022, Dec/15',
    description:
      'Holders of the first drops will get a three hour slot before the mint to get an exclusive chance to mint before the public sale.',
    icon: ScaleIcon,
  },
  {
    id: 4,
    name: '04 IMAGINAL-DISCS 111 drop 2023, Jan/8 - winners of three Holger Lippmanns',
    description:
      'We will announce three winners of an exclusive artwork of Holger Lippmann',
    icon: LightningBoltIcon,
  },
  {
    id: 5,
    name: '06 DATA-SCULPTURES 2023, tbd',
    description:
      'Access to amazing sculptures for the analog world',
    icon: LightningBoltIcon,
  },
]

const Roadmap: React.FC = () => {
  return (
    <div className="pb-12 overflow-hidden lg:pb-24">
      <div className="relative max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative justify-self-start">
            <h2 className="text-2xl font-extrabold text-gray-900">
              Roadmap
            </h2>

            <dl className="mt-10 space-y-10">
              {milestones.map((item) => (
                <div key={item.id} className="relative">
                  <p className="text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  <p className="mt-2 text-base text-gray-500">{item.description}</p>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 lg:pl-4 relative lg:mt-0 max-w-[100%] w-[490px] justify-self-end" aria-hidden="true">
            <Video sources={[{src: VideoFile, type: "video/mp4"}]} poster={VideoPoster} className="w-full" />
          </div>
        </div>


      </div>
    </div>
  )
}

export default Roadmap
