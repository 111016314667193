import React from "react"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import classNames  from "classnames"

const faqs = [
  {
    question: "What is an NFT?",
    answer:
      `
      <p>
        A non-fungible token, in short NFT, is a digital asset used to represent ownership of unique items or access, whether digital or physical. NFTS are digital collectibles. Ownership and transfer of an NFT is secured by a blockchain, which documents ownership on a decentralized, public database, simultaneously acting as a certificate of authenticity and unmasking counterfeits. The technology offers a revolutionary new way of selling art without any intermediaries, according to a crypto future for all business sectors. 
        In short, an NFT is a digital certificate of ownership, almost always bought and sold using cryptocurrency, to which any digital file – a jpeg image file, a giff file, a video, a song, a moment – can be attached.
      </p>
      `,
  },
  {
    question: "How can I buy Data-Donut Drops?",
    answer:
      `
      <p>
        The artist believes in carbon friendly certifications, that is provided with the Cardano blockchain.
        All you need is a Cardano Wallet (like Nami, Yoroi, eToro) and ADA, the cryptocurrency used to pay for the transaction on this page.
        For minting the DATA-DONUTS please send the required ADA to the given wallet address.
      </p>
      `,
  },
  {
    question: "What does \"Minting\" mean",
    answer:
      `
      <p>
        Minting is the process to convert a digital file into an NFT and transfer it to a blockchain.
      </p>
      `,
  },
  {
    question: "Which blockchain does the artist support?",
    answer: `The artist supports the Cardano blockchain.`,
  },
  {
    question: "How to connect a Cardano wallet?",
    answer: `
      <p>
        Wallets are required for the transfer of cryptocurrencies and digital assets as well as they are a storage for both. Choose a Cardano wallet of your preference ((like Nami, Yoroi, eToro) )and click download. Then „create a wallet“ and follow the instructions. Be aware: A wallet comprises a public and private key, which are cryptographically generated. 
        The public key is a combination of numbers and letters that function like an account number and enables the transfer of payments. 
        The private key is a secret signature key, also called seed code, that functions as your PIN for the account. It should be never, ever shared to anyone or lost! Without your keys you may loose all your assets and coins, so back them up and keep them safe!  
        At least you have to transfer ADA to your wallet to be able to purchase an NFT.
      </p>
    `,
  },
  {
    question: "How to transfer ADA to my wallet?",
    answer: `
      <p>
        To purchase a NFT you need ADA, which can be sent from another wallet or an exchange ( such as Coinbase, Exedos or Binance) to your wallet address. To do so,  just select „receive“ in your wallet and copy your wallet address.
        Alternatively, you can scan the receiving address QR code in the mobile wallet where you are sending the ADA from. 
        Every transaction of Coins or NFTs costs fees. Cardano transaction fees will be added on top of the NFT price. Make sure that you have more ADA than the purchase price.
      </p>
    `,
  },
  {
    question: "What are Cardano transaction fees?",
    answer: `
      <p>
        Every transaction of coins or NFTs costs fees, used by the Cardano network. More information can be found here: https://bit.ly/3GrQIqV. 
        You will need enough ADA in your wallet for the drop prices and the associated transaction fees. 
      </p>
    `,
  },
  {
    question: "How can I sell my NFTs?",
    answer: `
      <p>
        Secondary market solutions on Metadibs® will be provided in 2022. Until then, you can easily transfer your NFTs to blockchains supporting Cardano. 
        For each sale on a secondary marketplace a 10% royalty fee applies.
      </p>
    `,
  },
  {
    question: "Royalty fees",
    answer: `
      <p>
        If an NFT is listed and collected again on the secondary market, a 10% royalty is sent to the creator who originally produced the artwork.
        The NFT royalty payments are executed automatically by smart contracts.
      </p>
    `,
  },
  {
    question: "How can I view my Cardano NFTs?",
    answer: `
      <p>
        Please watch the following video on YouTube: <a href="https://www.youtube.com/watch?v=X1ursREe3N0">https://www.youtube.com/watch?v=X1ursREe3N0</a>
      </p>
    `,
  },
]

const About: React.FC = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto pb-12 lg:pb-24 px-4 py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-2xl font-extrabold text-gray-900">F.A.Q.</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500" dangerouslySetInnerHTML={{__html: faq.answer}} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default About