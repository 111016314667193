import React from "react"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import classNames  from "classnames"

const facts = [
  {
    question: "IMAGINAL-DISC, season 4 drop, 2022, Dec/15",
    answer:
      `
      <p>
        Conceptually, the metamorphosis of a butterfly served as the inspiration for the work. The caterpillar pupates and transitions into a liquid to re-emerge as an entirely new life form. These remains of cell nuclei, the so-called "IMAGINAL DISCS" contain all information to rebuild a completely new organism. This processing of data is imitated through the work of Holger Lippmann.<br/>
        Technically, the work is generated from different image-based, color-valued and color-localized data streams that are composed to form a radially arranged pattern. Combined with different randomization techniques, an image structure is written from the outside in.<br/>
        Visually, the artworks create a structure reminiscent of data discs, labyrinths, mandalas or blueprints of cities, archeological excavations or visual references from science fiction movies. The circle shape holds special meaning. The most balanced form, embodying total silence (ohm) and chaos (pi) at the same time. Infinite, without pattern, not solved. The associations are manyfold and spark the imagination of the viewer.<br/>
        <br />
        Animation mp4, Loop 14 seconds, made with code (Processing)
        <br />
        holger lippmann, december 2022
        <br />
      </p>
      `,
  },
  {
    question: "TRANSITERATE, season 3 drop, 2022, Jun/23",
    answer:
      `
      <p>
        A disk is divided into smaller and smaller partitions within a subdivision process (iteration).<br />
        at the circle level there are 1 to 16 subdivisions (rings), at the radial level there are 4 to 32 subdivisions (pie slices).<br />
        this process is controlled individually by means of various random values, so that each new build-up and decay cycle follows one another. on 30 seconds of video this results in 6 cycles that flow into one another.<br />
        the fillings of these "partitions" consist of color gradients, which are composed of 2 randomly chosen colors by means of a random line structure. several predefined color arrays result in a specific color composition.<br />
        the entire animation is basically generated from a line (line command) by various algorithms and loops.<br />
        <br />
        made with code (processing)
        <br />
        holger lippmann, june 2022
        <br />
      </p>
      `,
  },
  {
    question: "DATA-MOMENTS, season 2 drop, 2022, Feb/24",
    answer:
      `
      <p>
        These DATA-MOMENTS NFTs were created by pioneering computer art artist Holger Lippmann. It is about 99 unique variants of DATA moments based purely on strict geometries and musical components that plays with the tension of simple rules and ordered radial scenes. Also the new series evolve within the digital realm through code, brought to life through music waves, made with build with native instruments mk3 using monark for complex synth sounds and reaktor for acid 303 vibes.
      </p>
      <br /><br />
      <p>
      math is beautiful in its way of making natural things/laws visible.<br />
      a form of communication that portrays dense and complex information in graphical form.<br />
      <br />
      visualizing data by applying values to shapes, colors and algorithmic/geometrical structuring.<br />
      <br />
      more and more shapes are added and superimposed, only to be thinned out and structured again later.<br />
      <br />
      an ongoing process of modeling between input and output, finding a freeze, a final image. keeping only a few.<br />
      <br />
      presenting information in a way that detaches itself from the actual purpose, becoming poetry.<br />
      <br />
      <br />
      
      data-disc, data-donut, data-star<br />
      holger lippmann, january 2022<br />

      </p>
      `,
  },
  {
    question: "DATA-DONUT, OG / season 1 drop, 2021, Dec/23",
    answer:
      `
      <p>
        The ongoing project DATA-DONUT is dedicated to the beauty of codes. <br />
        Based on the open source software Processing, which was developed in early 2000 by a community of programming artists for artists, the DATA-DONUT series create systems, images and colour experiences that result from the data of carefully selected colour palettes. The starting point are squares whose arrangement results in a circle. The extraordinary value of the colourful artworks lies in the computer's universal capacity for expansion and repetition, the possibilities that result from the software, the Dadaist principle of discarding options, struggling with contingencies, destroying and recreating to the point of decision-making, so that each unique piece becomes a sensual pleasure.<br />
        In short: code-based process art<br />
      </p>
      <br />
      <br />
      <p>
        /*<br />
        <br />
        no_errors_found / generation 1 - 33 / nov-dec 2021<br />
        holger lippmann / <a href="www.e-art.co">www.e-art.co</a><br />
        resolution: 2556x2546<br />
        duration: 13 sec<br />
        framerate: 24fps<br />
        loop<br />
        made with code: processing / www.processing.org<br />
        <br />
        our basic organizing structure is the grid, the square.<br />
        combined with fast motion we have the wheel, partitioned into squares.<br />
        <br />
        reminiscent of write and read processes on data storage disks,<br />
        the "no_errors_found" work series<br />
        plays with the superimposition of various visual principles of representation;<br />
        <br />
        there is the smaller square - black and white cycle, representing "reason", and the larger color cycling squares representing "emotion" -<br />
        a virtual opposition of interests, which seemingly contradicting each other, but still meaningful, each wandering or jumping through its interconnected scale.<br />
        <br />
        the corresponding code data result from an interweaving of noise, sine/cosine and random values.<br />
        <br />
        the visible noise disturbances are disintegration structures developed in the code that follow the shape,<br />
        the circular arrangement along the "donut" structure, according to the fractal principle - the branch is like the tree  (benoit mandelbrot).<br />
        <br />
        holger lippmann, dec. 2021<br />
        <br />
        */<br />
    
      </p>
      `,
  },
  {
    question: "Holger Lippmann",
    answer:
      `
      <p>
        Holger Lippmann is a pioneer of net art, his medium: the computer. Formerly student and master student at the Hochschule für Bildende Künste in Dresden, trained at the CIMdata Institute, Berlin / international scholarships, e. g. at the Institut des Hautes Etudes en Arts Plastiques, Paris / collaboration at the Institute of Technology, New York / awarded with the "Teletext Art Prize" (2015), the International media award "POPULAR", Karlsruhe (2002) and the CD-ROM Press, Cynet art, Dresden / participation in numerous exhibitions, e.g. at the Museum Heidenheim, ZKM Karlsruhe and in the galleries DAM Berlin, Galerie Gebr. Lehmann, Dresden.
      </p>
      `,
  },
  {
    question: "Policy IDs",
    answer: `
      <p>
        DATA-DONUTS, OG drop season 1: af1e74090d58363c6875fbf955c5808455b12986ac6efe4dbe0d852a
        <br />
        <br />
        DATA-MOMENTS, drop season 2: de6278238440c2d106fbc0bf720de9cf291aaa96720c55d0e939250c
        <br />
        <br />
        TRANSITERATE, drop season 3: a76798b2d1b55c2030a756fb39f9838ea3d1adb569d6ae0d761fd99e
        <br />
        <br />
      </p>
      `,
  },
  {
    question: "Why Cardano?",
    answer: `
      <p>
        The NFT market has quickly evolved into a real perspective for artists to sustain and develop their unique perspectives and minds. It is without a doubt part of an evolution towards something new and existing.
      </p>
      <br />
      <p>
        To Holger Lippmann this NFT drop it is of the highest priority that the steps towards this evolution are being taken with a progressive and environmentally conscious mind.
      </p>
      <br />
      <p>
        With its proof-of-stake protocol, the Cardano blockchain stands as one of the most environmentally friendly blockchains. Cardano’s annual energy consumption are less than 0.01 % compared to Ethereum and even less than 0.001 % compared to Bitcoin.
      </p>
    `,
  },
]

const FAQ: React.FC = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto pb-12 lg:pb-24 px-4 py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-2xl font-extrabold text-gray-900">About</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {facts.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500" dangerouslySetInnerHTML={{__html: faq.answer}} />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default FAQ
