import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NavBar from "../components/navbar"
import About from "../components/about"
import FAQ from "../components/faq"
import RoadMap from "../components/roadmap"
import Hero from "../components/hero"
import AllNFTs from "../components/allNfts"
import JoinMailingList from "../components/joinMailingList"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="NFTs by HOLGER LIPPMANN" />
      <NavBar />

      <Hero />
      <About />
      <AllNFTs />
      <RoadMap />
      <FAQ />
      <JoinMailingList />
    </Layout>
  )
}

export default IndexPage
