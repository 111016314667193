
import React from 'react';

type Props = {
  saleType: string,
  projectTitle: string,
}

const pgPre = process.env.GATSBY_PG_PRE
const pgPublic = process.env.GATSBY_PG_PUBLIC
const amountToMint = process.env.GATSBY_AMOUNT_TO_MINT

const PaymentGatewayButton: React.FC<Props> = ({saleType, projectTitle}) => {
  const openPaymentWindow = () => {
    let paymentUrl;
    switch(saleType) {
      case "PRESALE":
        paymentUrl = `https://payment.nft-maker.io/?p=${pgPre}&c=${amountToMint}`;
        break;
        case "PUBLICSALE":
        paymentUrl = `https://payment.nft-maker.io/?p=${pgPublic}&c=${amountToMint}`;
        break;
    }

    // Specify the popup width and height
    const popupWidth = 500;
    const popupHeight = 700;

    // Calculate the center of the screen
    let left, top = 0;
    if(typeof window !== "undefined"){
      left = window.top.outerWidth / 2 + window.top.screenX - ( popupWidth / 2);
      top = window.top.outerHeight / 2 + window.top.screenY - ( popupHeight / 2);

      window.open(paymentUrl, "NFT-MAKER PRO Payment Gateway",  `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`);
    }
  }


  return (
    <div className="flex">
        <a
          className="cursor-pointer hover:bg-blue rounded-full inline-block bg-black text-white filter backdrop-blur z-20 text-sm md:text-base px-6 lg:px-8 py-4 lg:p-8 my-4"
          onClick={openPaymentWindow}
        >
          <span className="text-[14px] lg:text-[17px] leading-none">
            {`Claim ${projectTitle} NFT` }
          </span>
        </a>
    </div>
  );
}

export default PaymentGatewayButton;
